import React, { useEffect, useMemo } from 'react'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { Box, Button, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag'
import InstagramIcon from '@mui/icons-material/Instagram'
import ScrollAnimation from 'react-animate-on-scroll'
import Masonry from '@mui/lab/Masonry'

import 'animate.css/animate.compat.css'
import './App.css'

const images = [
  'Famous_in_Dallas_-_6_of_46',
  'Famous_in_Dallas_-_38_of_46',
  'Famous_in_Dallas_-_1_of_46',
  'Famous_in_Dallas_-_9_of_46',
  'Famous_in_Dallas_-_3_of_46',
  'Famous_in_Dallas_-_11_of_46',
  'Famous_in_Dallas_-_14_of_46',
  'Famous_in_Dallas_-_43_of_46',
  'Famous_in_Dallas_-_18_of_46',
  'Famous_in_Dallas_-_23_of_46',
  'Famous_in_Dallas_-_27_of_46',
  'Famous_in_Dallas_-_29_of_46',
  'Famous_in_Dallas_-_31_of_46',
  'Famous_in_Dallas_-_34_of_46',
  'Famous_in_Dallas_-_36_of_46',
  'Famous_in_Dallas_-_40_of_46',
  'Famous_in_Dallas_-_42_of_46'
]

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCRahjulsHYmAODWBX8CW1H8KIcitjJrpQ',
  authDomain: 'famous-in-dallas.firebaseapp.com',
  projectId: 'famous-in-dallas',
  storageBucket: 'famous-in-dallas.appspot.com',
  messagingSenderId: '272078183363',
  appId: '1:272078183363:web:bd8dd63d74bda309a8fb76',
  measurementId: 'G-7V451HT5ZB'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const ShopButton: React.FC = () => {
  function logShopClickEvent (): void {
    logEvent(analytics, 'select_content', {
      content_type: 'link',
      content_id: 'shop-button',
      screen: 'landing'
    })
  }

  return (
    <Button className='App-shop-button' variant='contained' href='https://shop.famousindallas.com' onClick={() => { logShopClickEvent() }} startIcon={<ShoppingBagIcon />}>
      <Typography variant='button'>shop</Typography>
    </Button>
  )
}

const App: React.FC = () => {
  const theme = useTheme()
  const multiColumn = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'landing',
      firebase_screen_class: undefined
    })
  }, [])

  const columns = useMemo(() => {
    if (multiColumn) return 3

    return 1
  }, [multiColumn])

  console.log(Math.floor(columns / 2))

  return (
    <div className="App">
      <header className="App-header">
        <picture>
          <source srcSet={'/assets/logo-white-480.webp'} type="image/webp" />
          <img src={'/assets/logo-white-480.jpg'} className="App-logo" alt="Famous in Dallas logo" loading='eager' />
        </picture>
      </header>
      <article className='App-body'>
        <ShopButton />
        <Button variant='outlined' startIcon={<InstagramIcon />} className='App-ig-button' style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.7)', borderColor: 'rgba(0, 0, 0, 0.9)' }} href='https://www.instagram.com/famous_in_dallas/' target='_blank' rel='noopener noreferrer'>Instagram</Button>
        <Box sx={{ width: '100%', maxWidth: '1200px' }}>
          <Masonry columns={columns} spacing={2}>
            {images.map((image, index) => (
              <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={0.75} animateOnce={index === Math.floor(columns / 2)} initiallyVisible={index === Math.floor(columns / 2)} animatePreScroll={index === Math.floor(columns / 2)} key={image}>
                <picture>
                  <source srcSet={`/assets/webp/${image}.webp`} type="image/webp" />
                  <img className='App-image' src={`/assets/jpg/${image}.jpg`} alt={image} loading={index === Math.floor(columns / 2) ? 'eager' : 'lazy'} />
                </picture>
              </ScrollAnimation>
            ))}
          </Masonry>
        </Box>
        <ShopButton />
        <Typography variant='caption' color={'white'}>Website by <Link href='https://habeeb.bio' target='_blank' rel='noopener noreferrer'>Habeeb Hooshmand</Link></Typography>
      </article>
    </div>
  )
}

export default App
